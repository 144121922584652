import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import unorm from "unorm";

const initialState = {
  data: [
    {
      id: "",
      created_at: "",
      updated_at: "",
      name: "",
      secondName: "",
      lastName1: "",
      lastName2: "",
      semester: 0,
      amount: "",
      amounts: [],
      startGeneration: "",
      endGeneration: "",
      idExternalUser: "",
      reference: "",
      registrationReference:"",
      status: 0,
      pathPhoto: "",
      tutorFullName: "",
      emailTutor: "",
      scholarshipStatus: "",
      scholarshipPercentage: "",
      typeOfScholarship: "",
      tutorRelationship: "",
      tutorPhone: "",
      phone: "",
      professionTutor: "",
      enterpriceTutor: "",
      enterpriceAddressTutor: "",
      type:""
    },
  ],
  isLoading: false,
  filter: [],
  semesterFilter: 0,
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const getStudentAsync = createAsyncThunk(
  "students/getStudentAsync",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC + "/students/getAllActive",
        { headers: headers }
      );

      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const getStudentByIdExternalUser = createAsyncThunk(
  "students/getStudentByIdExternalUser",
  async (idExternalUser) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(
        process.env.REACT_APP_API_CORE_IMC +
          "/students/getStudentByIdExternalUser/" +
          idExternalUser,
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const upsertStudent = createAsyncThunk(
  "students/upsertStudent",
  async (formData) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/students/upsertStudent",
        formData,
        config
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const insertAmount = createAsyncThunk(
  "students/insertAmount",
  async (payload) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/students/insertAmount",
        payload,
        config
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const deleteAmount = createAsyncThunk(
  "students/deleteAmount",
  async ({ idAmount, idUser }) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/students/deleteAmount",
        { idAmount: idAmount, idUser: idUser },
        { headers: headers }
      );
      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const studentsSlice = createSlice({
  name: "students",
  initialState: initialState,
  reducers: {
    updateSemesterFilter: (state, action) => {
      state.semesterFilter = action.payload;
      state.filterStudents = state.data;

      if (action.payload !== 0) {
        const filtered = state.data.filter(function (el) {
          return el.semester === action.payload;
        });

        if (filtered) {
          state.filter = filtered;
        }
      } else {
        state.filter = state.data;
      }
    },
    filterStudents: (state, action) => {
      const { payload } = action;
      const { data, semesterFilter } = state;

      // Función para normalizar y convertir a minúsculas
      const normalizeAndLowercase = (str) => unorm.nfkd(str).toLowerCase();

      if (payload !== "") {
        const filteredData = data.filter((student) => {
          const fullName = `${student.name} ${student.secondName || ""} ${
            student.lastName1
          } ${student.lastName2}`;
          return (
            (semesterFilter === 0 || student.semester === semesterFilter) &&
            (normalizeAndLowercase(student.name).includes(
              normalizeAndLowercase(payload)
            ) ||
              normalizeAndLowercase(student.lastName1).includes(
                normalizeAndLowercase(payload)
              ) ||
              normalizeAndLowercase(student.lastName2).includes(
                normalizeAndLowercase(payload)
              ) ||
              normalizeAndLowercase(String(student.reference)).includes(
                normalizeAndLowercase(payload)
              ) ||
              normalizeAndLowercase(
                student.name + " " + student.lastName1
              ).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(
                student.name + " " + student.lastName1 + " " + student.lastName2
              ).includes(normalizeAndLowercase(payload)) ||
              normalizeAndLowercase(fullName).includes(
                normalizeAndLowercase(payload)
              ))
          );
        });

        state.filter = filteredData;
      } else {
        if (semesterFilter === 0) {
          state.filter = data;
        } else {
          const filteredData = data.filter(
            (student) => student.semester === semesterFilter
          );
          state.filter = filteredData;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentAsync.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getStudentAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.filter = action.payload.data;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getStudentAsync.rejected, (state, action) => {
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(getStudentByIdExternalUser.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(getStudentByIdExternalUser.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(getStudentByIdExternalUser.rejected, (state, action) => {
        state.error = {
          code: action.error.code,
          message: action.error.message,
        };
        state.isLoading = false;
      })
      .addCase(upsertStudent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(upsertStudent.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(upsertStudent.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(insertAmount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(insertAmount.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(insertAmount.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteAmount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAmount.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteAmount.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { filterStudents, updateSemesterFilter } = studentsSlice.actions;

export default studentsSlice.reducer;
