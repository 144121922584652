import React from 'react';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const StatusCard = ({ title, isPaid }) => {
    return (
        <Card
            sx={{
               
                backgroundColor: isPaid ? '#e0f7fa' : '#ffebee',
                boxShadow: 3,
                borderRadius: 3,
            }}
        >
            <CardContent>
                <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                >
                    {title}
                </Typography>

                <Divider sx={{ mb: 2 }} />

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {isPaid ? (
                        <>
                            <CheckCircleIcon sx={{ fontSize: 30, color: 'green', mr: 1 }} />
                            <Typography variant="body1" sx={{ color: 'green', fontWeight: 'bold' }}>
                                Pago Completado
                            </Typography>
                        </>
                    ) : (
                        <>
                            <ErrorIcon sx={{ fontSize: 30, color: 'red', mr: 1 }} />
                            <Typography variant="body1" sx={{ color: 'red', fontWeight: 'bold' }}>
                                Pago Pendiente
                            </Typography>
                        </>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default StatusCard;
