import React, { useEffect } from "react";
import CardsDropDown from "../../components/CardsDropDown";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { useLocation } from "react-router-dom";
import { getStudentByIdExternalUser } from "../../features/students/studentsSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getPaymentsByIdExternalUser } from "../../features/payments/paymentsSlice";
import Loader from "../../components/Loader";
import Back from "../../components/Back";
import DangerousIcon from "@mui/icons-material/Dangerous";
import Avatar from "@mui/material/Avatar";
import moment from "moment";

function Semestres() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const students = useSelector((state) => state.students.data);
  const payments = useSelector((state) => state.payments.data);
  const isLoadingStudents = useSelector((state) => state.students.isLoading);
  const isLoadingPayments = useSelector((state) => state.payments.isLoading);
  const uuid = path.split("/")[3];
  const currentMonth = moment().format("M");
  const currentCycle =
    moment().format("YYYY") + "-" + (currentMonth > 6 ? "B" : "A");

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getStudentByIdExternalUser(uuid));
    };

    fetchData();
  }, [dispatch, uuid]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getPaymentsByIdExternalUser(uuid));
    };

    fetchData();
  }, [dispatch, uuid]);

  let semesters = [];

  function nameSemester(semester) {
    if (semester === 1) {
      return "Primer";
    } else if (semester === 2) {
      return "Segundo";
    } else if (semester === 3) {
      return "Tercer";
    } else if (semester === 4) {
      return "Cuarto";
    } else if (semester === 5) {
      return "Quinto";
    } else if (semester === 6) {
      return "Sexto";
    }
  }

  let currentSemester=true

  if (payments.length > 0) {
    for (let index = 0; index < payments.length; index++) {
      const anio = payments[index].ciclo
        ? payments[index].ciclo.split("-")[0]
        : "";

      let agregar = true;
      

      for (let j = 0; j < semesters.length; j++) {
        if (semesters[j].semester === payments[index].semester) {
          agregar = false;
        }
        if (payments[index].semester===students.semester) {
          currentSemester = false;
        }
      }

      if (agregar) {
        semesters.push({
          semester: payments[index].semester,
          nameSemester: nameSemester(payments[index].semester) + " Semestre",
          description: payments[index].ciclo
            ? payments[index].ciclo.includes("A")
              ? "Enero a junio del " + anio
              : "Julio a diciembre del " + anio
            : "",
          ciclo: payments[index].ciclo,
        });
      }
    }
  } else {
    const anio = currentCycle.split("-")[0];

    semesters.push({
      semester: students.semester,
      nameSemester: nameSemester(students.semester) + " Semestre",
      description: currentCycle
        ? currentCycle.includes("A")
          ? "Enero a junio del " + anio
          : "Julio a diciembre del " + anio
        : "",
      ciclo: currentCycle,
    });
  }

  if(currentSemester){
    const anio = currentCycle.split("-")[0];

    semesters.push({
      semester: students.semester,
      nameSemester: nameSemester(students.semester) + " Semestre",
      description: currentCycle
        ? currentCycle.includes("A")
          ? "Enero a junio del " + anio
          : "Julio a diciembre del " + anio
        : "",
      ciclo: currentCycle,
    });
  }

  semesters.sort((a, b) => b.semester - a.semester);

  return (
    <Box>
      <Loader loading={isLoadingStudents && isLoadingPayments} />
      <Container>
        <NavBar />
        <Box sx={{ mt: -4 }}>
          <Back ruta={"/" + path.split("/")[1] + "/" + path.split("/")[2]} />
        </Box>
        <Box>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ mb: 3, textAlign: "left" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="Avatar"
                  src={students.pathPhoto}
                  sx={{
                    width: { md: 200, xs: 150 },
                    height: { md: 200, xs: 150 },
                    mr: { md: "40px" },
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: 20, md: 36 },
                      color: "#264496",
                      fontFamily: "Nunito, sans-serif",
                    }}
                  >
                    {students.name +
                      (students.secondName
                        ? " " + students.secondName + " "
                        : " ") +
                      students.lastName1 +
                      " " +
                      students.lastName2}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#264496",
                      fontFamily: "Nunito, sans-serif",
                      fontSize: { xs: 20, md: 24 },
                      mt: "-10px",
                    }}
                  >
                    Semestre: {students.semester}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#264496",
                      fontFamily: "Nunito, sans-serif",
                      fontSize: { xs: 20, md: 24 },
                      mt: "-10px",
                    }}
                  >
                    {students?.typeOfScholarship &&
                    students?.typeOfScholarship !== "ninguna"
                      ? "Tipo de beca:" + students.typeOfScholarship
                      : ""}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#264496",
                      fontFamily: "Nunito, sans-serif",
                      fontSize: { xs: 20, md: 24 },
                      mt: "-10px",
                    }}
                  >
                    {students?.typeOfScholarship &&
                    students?.typeOfScholarship !== "ninguna"
                      ? "Porcentaje: " + students.scholarshipPercentage + "%"
                      : ""}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#264496",
                      fontFamily: "Nunito, sans-serif",
                      fontSize: { xs: 20, md: 24 },
                      mt: "-10px",
                    }}
                  >
                    {students?.typeOfScholarship &&
                    students?.typeOfScholarship !== "ninguna"
                      ? "Estado de beca: " + students.scholarshipStatus
                      : ""}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 20, md: 24 },
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mt: "-10px",
                    }}
                  ></Typography>
                </Box>
              </Box>

              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 20, md: 32 },
                  color: "#264496",
                  fontFamily: "Nunito, sans-serif",
                  textAlign: { xs: "center", md: "left" },
                  mt: { xs: "20px" },
                }}
              >
                Historial de pagos
              </Typography>
            </Box>
            {semesters.length === 0 ? (
              <Box>
                <Typography
                  sx={{
                    pt: 5,
                    fontWeight: "bold",
                    fontSize: { xs: 24, md: 32 },
                    color: "red",
                  }}
                >
                  No hemos registrado pagos en tu cuenta. Por favor, comunícate
                  con el área de administración para más información.
                </Typography>
                <DangerousIcon sx={{ fontSize: "200px", color: "#d50000" }} />
              </Box>
            ) : null}
          </Box>

          <Grid container spacing={2}>
            {semesters.map((semester, index) => (
              <Grid
                item
                md={3}
                xs={12}
                justifyContent="center"
                alignItems="center"
                key={index}
              >
                <a onClick={() => history(path + "/" + semester.ciclo)}>
                  <CardsDropDown
                    avatar={semester.semester}
                    title={semester.nameSemester}
                    description={semester.description}
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Semestres;
